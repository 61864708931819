import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Tr from './Tr'
import Td from './Td'
import TBody from './TBody'
import THead from './THead'
import Th from './Th'

const Table = React.forwardRef((props, ref) => {
  const {
    borderlessRow,
    children,
    className,
    hoverable,
    compact,
    oveerflow = true,
    asElement: Component,
    ...rest
  } = props

  const tableClass = classNames(
    Component === 'table' ? 'table-default' : 'table-flex',
    hoverable && 'table-hover',
    compact && 'table-compact',
    borderlessRow && 'borderless-row',
    className
  )

  return (
    <div className={classNames(oveerflow && 'overflow-x-auto')}>
      <Component className={tableClass} {...rest} ref={ref}>
        {children}
      </Component>
    </div>
  )
})

Table.propTypes = {
  hoverable: PropTypes.bool,
  compact: PropTypes.bool,
  asElement: PropTypes.string,
  borderlessRow: PropTypes.bool,
}

Table.defaultProps = {
  hoverable: true,
  compact: false,
  asElement: 'table',
  borderlessRow: false,
}

Tr.defaultProps = {
  asElement: 'tr',
}

Td.defaultProps = {
  asElement: 'td',
}

TBody.defaultProps = {
  asElement: 'tbody',
}

Th.defaultProps = {
  asElement: 'th',
}

THead.defaultProps = {
  asElement: 'thead',
}
export default Table
