import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Pager from './Pagers'
import Prev from './Prev'
import Next from './Next'
import Total from './Total'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useConfig } from '../ConfigProvider'
import { IoMdArrowDropdown } from 'react-icons/io'

const Pagination = (props) => {
  const {
    className,
    currentPage,
    displayTotal,
    onChange,
    pageSize,
    total,
    rowPerPage = false,
    setPerPage,
  } = props

  const rows = [10, 20, 30]

  const [paginationTotal, setPaginationTotal] = useState(total)
  const [internalPageSize, setInternalPageSize] = useState(pageSize)
  const [showList, setShowList] = useState(false)
  const [selectedOption, setSelectedOptions] = useState({ index: 2, row: 30 })

  const { themeColor, primaryColorLevel } = useConfig()

  const getInternalPageCount = useMemo(() => {
    if (typeof paginationTotal === 'number') {
      return Math.ceil(paginationTotal / internalPageSize)
    }
    return null
  }, [paginationTotal, internalPageSize])

  const getValidCurrentPage = useCallback(
    (count) => {
      const value = parseInt(count, 10)
      let internalPageCount = getInternalPageCount
      let resetValue
      if (!internalPageCount) {
        if (isNaN(value) || value < 1) {
          resetValue = 1
        }
      } else {
        if (value < 1) {
          resetValue = 1
        }
        if (value > internalPageCount) {
          resetValue = internalPageCount
        }
      }

      if ((resetValue === undefined && isNaN(value)) || resetValue === 0) {
        resetValue = 1
      }

      return resetValue === undefined ? value : resetValue
    },
    [getInternalPageCount]
  )

  const [internalCurrentPage, setInternalCurrentPage] = useState(
    currentPage ? getValidCurrentPage(currentPage) : 1
  )

  useEffect(() => {
    if (total !== paginationTotal) {
      setPaginationTotal(total)
    }

    if (pageSize !== internalPageSize) {
      setInternalPageSize(pageSize)
    }

    if (currentPage !== internalCurrentPage) {
      setInternalCurrentPage(currentPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, pageSize, currentPage])

  const onPaginationChange = (val) => {
    setInternalCurrentPage(getValidCurrentPage(val))
    onChange?.(getValidCurrentPage(val))
  }

  const onPrev = useCallback(() => {
    const newPage = internalCurrentPage - 1
    setInternalCurrentPage(getValidCurrentPage(newPage))
    onChange?.(getValidCurrentPage(newPage))
  }, [onChange, internalCurrentPage, getValidCurrentPage])

  const onNext = useCallback(() => {
    const newPage = internalCurrentPage + 1
    setInternalCurrentPage(getValidCurrentPage(newPage))
    onChange?.(getValidCurrentPage(newPage))
  }, [onChange, internalCurrentPage, getValidCurrentPage])

  const pagerClass = {
    default: 'pagination-pager',
    inactive: 'pagination-pager-inactive',
    active: `text-${themeColor}-${primaryColorLevel} bg-${themeColor}-50 hover:bg-${themeColor}-50 dark:bg-${themeColor}-${primaryColorLevel} dark:text-gray-100`,
    disabled: 'pagination-pager-disabled',
  }

  const paginationClass = classNames('pagination', className)

  const toogleList = () => {
    setShowList((prevShowList) => !prevShowList)
  }

  const handleOptionSelect = (index, row) => {
    setSelectedOptions({ index, row })
    setPerPage(`per_page=${row}`)
  }

  useEffect(() => {
    //
  }, [selectedOption])

  const RowPage = () => {
    return (
      <div className="flex gap-2 items-center pr-6">
        Rows per page<span>: </span>
        <div className="">
          <div
            className="py-1 px-2 bg-slate-200 w-fit text-gray-600 rounded-md font-bold flex items-center gap-1 justify-center cursor-pointer"
            onClick={() => toogleList()}
          >
            {selectedOption.row}
            <span className="text-base">
              <IoMdArrowDropdown />
            </span>
          </div>
          {showList && (
            <div className="absolute mt-2 font-bold w-fit">
              <ul>
                {rows.map((row, index) => (
                  <li
                    key={index}
                    className={`cursor-pointer flex items-center py-1 px-4 w-full ${
                      selectedOption.index === index
                        ? `bg-slate-300 hover:bg-slate-200`
                        : `bg-slate-200 hover:bg-slate-300`
                    }`}
                    onClick={() => handleOptionSelect(index, row)}
                  >
                    {row}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={paginationClass}>
      {rowPerPage && total ? <RowPage /> : null}
      {displayTotal && <Total total={total} />}
      <Prev
        currentPage={internalCurrentPage}
        pagerClass={pagerClass}
        onPrev={onPrev}
      />
      <Pager
        onChange={onPaginationChange}
        pageCount={getInternalPageCount}
        currentPage={internalCurrentPage}
        pagerClass={pagerClass}
      />
      <Next
        currentPage={internalCurrentPage}
        pageCount={getInternalPageCount}
        pagerClass={pagerClass}
        onNext={onNext}
      />
    </div>
  )
}

Pagination.propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number,
  displayTotal: PropTypes.bool,
  onChange: PropTypes.func,
  pageSize: PropTypes.number,
  total: PropTypes.number,
  rowPerPage: PropTypes.bool,
  setPerPage: PropTypes.func,
}

Pagination.defaultProps = {
  pageSize: 1,
  currentPage: 1,
  total: 5,
  displayTotal: false,
}

export default Pagination
